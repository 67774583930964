import Vue from 'vue'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    currentQuery: null,
    convoFilters: false,
    convoRepeatersView: false,
    reminderForms: false,
    promotionShowEvaluation: false,
    convoRetrieveApplicant: false,
    convoPanelMeeting: false,
    addFavorite: false,
    tableSettings: false,
    tableSettingsFields: [],
    tableSettingsTable: null,
    infoSheet: false,
    newSection: false,
    sendNotification: false,
    tableClicked: null,
    deleteModal: false,
    deleteData: {},
    accessModal: false,
    dataModal: {},
    otpModal: false,
    otpData: {},
    reportModal: false,
    reportData: {},
    showAdminMenu: true,
    formEvaluation: false,
    userTraces: false,
    notifyEvaluators: false,
    formData: false,
    exportLoading: false,
    reorganizeModal: false,
    reorganizeData: {},
    hasRelations: false,
    promotionCall: false,
    proposeEvaluator: false,
    convoViewPeers: false,
    sendMailTemplate: false,
    changedPassword: true,
    choosePublications: false,
    showEvaluatorMenu: false,
    filterToken: null,
    caeExport: false,
    createAlert: false,
    checkSignatureStatus: false,
    currentRoutePermission: null,
    addRequestModal: false,
  },
  getters: {
    currentRoutePermission: state => state.currentRoutePermission,
    addRequestModal: state => state.addRequestModal,
    createAlert: state => state.createAlert,
    convoFilters: state => state.convoFilters,
    promotionShowEvaluation: state => state.promotionShowEvaluation,
    convoRepeatersView: state => state.convoRepeatersView,
    convoRetrieveApplicant: state => state.convoRetrieveApplicant,
    convoPanelMeeting: state => state.convoPanelMeeting,
    addFavorite: state => state.addFavorite,
    tableSettings: state => state.tableSettings,
    tableSettingsFields: state => state.tableSettingsFields,
    tableSettingsTable: state => state.tableSettingsTable,
    infoSheet: state => state.infoSheet,
    newSection: state => state.newSection,
    sendNotification: state => state.sendNotification,
    tableClicked: state => state.tableClicked,
    deleteModal: state => state.deleteModal,
    deleteData: state => state.deleteData,
    accessModal: state => state.accessModal,
    otpModal: state => state.otpModal,
    otpData: state => state.otpData,
    reportModal: state => state.reportModal,
    reportData: state => state.reportData,
    showAdminMenu: state => state.showAdminMenu,
    formEvaluation: state => state.formEvaluation,
    userTraces: state => state.userTraces,
    notifyEvaluators: state => state.notifyEvaluators,
    formData: state => state.formData,
    reorganizeModal: state => state.reorganizeModal,
    reorganizeData: state => state.reorganizeData,
    hasRelations: state => state.hasRelations,
    promotionCall: state => state.promotionCall,
    exportLoading: state => state.exportLoading,
    proposeEvaluator: state => state.proposeEvaluator,
    convoViewPeers: state => state.convoViewPeers,
    sendMailTemplate: state => state.sendMailTemplate,
    changedPassword: state => state.changedPassword,
    choosePublications: state => state.choosePublications,
    filterToken: state => state.filterToken,
    caeExport: state => state.caeExport,
    showEvaluatorMenu: state => state.showEvaluatorMenu,
    reminderForms: state => state.reminderForms,
    checkSignatureStatus: state => state.checkSignatureStatus,
  },
  mutations: {
    TOGGLE_REMINDER_FORMS(state, reminderForms) {
      state.reminderForms = reminderForms
    },
    TOGGLE_REQUEST_MODAL(state, addRequestModal) {
      state.addRequestModal = addRequestModal
    },
    TOGGLE_SHOW_CAE_EXPORT(state, caeExport) {
      state.caeExport = caeExport
    },
    SAVE_CURRENT_QUERY(state, currentQuery) {
      state.currentQuery = currentQuery
    },
    TOGGLE_SHOW_EVALUATION(state, show) {
      state.promotionShowEvaluation = show
    },
    TOGGLE_NOTIFY_EVALUATORS(state, show) {
      state.notifyEvaluators = show
    },
    TOGGLE_USER_TRACES(state, show) {
      state.userTraces = show
    },
    TOGGLE_EXPORT_LOADING(state, exportLoading) {
      state.exportLoading = exportLoading
    },
    TOGGLE_FORM_DATA(state, show) {
      state.formData = show
    },
    TOGGLE_CREATE_ALERT(state, show) {
      state.createAlert = show
    },
    TOGGLE_FORM_EVALUATION(state, show) {
      state.formEvaluation = show
    },
    TOGGLE_ADD_FAVORITES(state, show) {
      state.addFavorite = show
    },
    TOGGLE_CONVO_FILTERS(state, show) {
      state.convoFilters = show
    },
    TOGGLE_CONVO_REPEATERS_VIEW(state, show) {
      state.convoRepeatersView = show
    },
    TOGGLE_CONVO_RETRIEVE_APPLICANT(state, show) {
      state.convoRetrieveApplicant = show
    },
    TOGGLE_TABLE_SETTINGS(state, show) {
      state.tableSettings = show
    },
    TOGGLE_INFO_SHEET(state, show) {
      state.infoSheet = show
    },
    TOGGLE_CHOOSE_PUBLICATIONS(state, show) {
      state.choosePublications = show
    },
    LOAD_FIELDS(state, fields) {
      state.tableSettingsFields = fields
    },
    SAVE_TABLE(state, table) {
      state.tableSettingsTable = table
    },
    TOGGLE_NEW_SECTION(state, show) {
      state.newSection = show
    },
    TOGGLE_SEND_NOTIFICATION(state, show) {
      state.sendNotification = show
    },
    TOGGLE_TABLE_CLICKED(state, id) {
      state.tableClicked = id
    },
    SHOW_DELETE_MODAL(state, show) {
      state.deleteModal = show
    },
    SHOW_ACCESS_MODAL(state, show) {
      state.accessModal = show
    },
    SHOW_OTP_MODAL(state, show) {
      state.otpModal = show
    },
    SHOW_REPORT_MODAL(state, show) {
      state.reportModal = show
    },
    LOAD_OTP_DATA(state, otpData) {
      state.otpData = otpData
    },
    LOAD_REPORT_DATA(state, reportData) {
      state.reportData = reportData
    },
    LOAD_DELETE_DATA(state, deleteData) {
      state.deleteData = deleteData
    },
    SHOW_ADMIN_MENU(state, showAdminMenu) {
      state.showAdminMenu = showAdminMenu
    },
    TOGGLE_PANEL_MEETING(state, convoPanelMeeting) {
      state.convoPanelMeeting = convoPanelMeeting
    },
    SHOW_REORGANIZE_MODAL(state, show) {
      state.reorganizeModal = show
    },
    LOAD_REORGANIZE_DATA(state, reorganizeData) {
      state.reorganizeData = reorganizeData
    },
    SAVE_HASRELATION(state, hasRelations) {
      state.hasRelations = hasRelations
    },
    SHOW_PROMOTION_CALL(state, show) {
      state.promotionCall = show
    },
    SHOW_PROPOSE_EVALUATOR(state, show) {
      state.proposeEvaluator = show
    },
    SHOW_CONVO_VIEW_PEERS(state, show) {
      state.convoViewPeers = show
    },
    TOGGLE_SEND_MAIL_TEMPLATE(state, sendMailTemplate) {
      state.sendMailTemplate = sendMailTemplate
    },
    TOGGLE_SIGNATURE_STATUS(state, checkSignatureStatus) {
      state.checkSignatureStatus = checkSignatureStatus
    },
    SAVE_CHANGED_PASSWORD(state, changedPassword) {
      state.changedPassword = changedPassword
    },
    TOGGLE_EVALUATOR_MENU(state, showEvaluatorMenu) {
      state.showEvaluatorMenu = showEvaluatorMenu
    },
    TOGGLE_ACTIVE_FILTER_TAB(state, token) {
      state.filterToken = token
    },
    SAVE_CURRENT_ROUTE_PERMISSION(state, permission) {
      state.currentRoutePermission = permission
    },
  },
  actions: {
    async toggleRequestModal({ commit }, visibility) {
      commit('TOGGLE_REQUEST_MODAL', visibility)
    },
    async toggleCreateItem({ commit }, visibility) {
      commit('TOGGLE_CREATE_ALERT', visibility)
    },
    async toggleCaeExport({ commit }, visibility) {
      commit('TOGGLE_SHOW_CAE_EXPORT', visibility)
    },
    async toggleSendMailTemplate({ commit }, visibility) {
      commit('TOGGLE_SEND_MAIL_TEMPLATE', visibility)
    },
    async toggleNotifyEvaluators({ commit }, visibility) {
      commit('TOGGLE_NOTIFY_EVALUATORS', visibility)
    },
    async toggleExportLoading({ commit }, visibility) {
      commit('TOGGLE_EXPORT_LOADING', visibility)
    },
    async toggleCheckSignatureStatus({ commit }, visibility) {
      commit('TOGGLE_SIGNATURE_STATUS', visibility)
    },
    async toggleActiveFilterTab({ commit }, token) {
      commit('TOGGLE_ACTIVE_FILTER_TAB', token)
    },
    async toggleConvoFilters({ commit }, visibility) {
      commit('TOGGLE_CONVO_FILTERS', visibility)
    },
    async toggleAddFavorites({ commit }, visibility) {
      commit('TOGGLE_ADD_FAVORITES', visibility)
    },
    async toggleConvoRepeatersView({ commit }, visibility) {
      commit('TOGGLE_CONVO_REPEATERS_VIEW', visibility)
    },
    async toggleChoosePublications({ commit }, visibility) {
      commit('TOGGLE_CHOOSE_PUBLICATIONS', visibility)
    },
    async toggleConvoRetrieveApplicant({ commit }, visibility) {
      commit('TOGGLE_CONVO_RETRIEVE_APPLICANT', visibility)
    },
    async toggleTableSettings({ commit }, visibility) {
      commit('TOGGLE_TABLE_SETTINGS', visibility)
    },
    async toggleNewSection({ commit }, visibility) {
      commit('TOGGLE_NEW_SECTION', visibility)
    },
    async toggleSendNotification({ commit }, visibility) {
      commit('TOGGLE_SEND_NOTIFICATION', visibility)
    },
    async toggleInfoItemSheet({ commit }, visibility) {
      commit('TOGGLE_INFO_SHEET', visibility)
    },
    async toggleUserTraces({ commit }, visibility) {
      commit('TOGGLE_USER_TRACES', visibility)
    },
    async toggleReminderForms({ commit }, visibility) {
      commit('TOGGLE_REMINDER_FORMS', visibility)
    },
    async toggleFormEvaluation({ commit }, visibility) {
      commit('TOGGLE_FORM_EVALUATION', visibility)
    },
    async toggleFormData({ commit }, visibility) {
      commit('TOGGLE_FORM_DATA', visibility)
    },
    async showDeleteModal({ commit }, visibility) {
      commit('SHOW_DELETE_MODAL', visibility)
    },
    async showAccessModal({ commit }, visibility) {
      commit('SHOW_ACCESS_MODAL', visibility)
    },
    async showOtpModal({ commit }, visibility) {
      commit('SHOW_OTP_MODAL', visibility)
    },
    async showReportModal({ commit }, visibility) {
      commit('SHOW_REPORT_MODAL', visibility)
    },
    async checkPassword({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/user/check-password`, data)
        if (resp.data.status === 'OK') {
          console.log(resp.data)
          commit('SAVE_CHANGED_PASSWORD', false)
        } else {
          commit('SAVE_CHANGED_PASSWORD', true)
        }
        return resp.data.status
      } catch (e) {
        console.log(e)
        return 'KO'
      }
    },
    async showReorganizeModal({ commit }, visibility) {
      commit('SHOW_REORGANIZE_MODAL', visibility)
    },
    async toggleTableClicked({ commit }, id) {
      commit('TOGGLE_TABLE_CLICKED', id)
    },
    async toggleEvaluatorMenu({ commit }, visibility) {
      commit('TOGGLE_EVALUATOR_MENU', visibility)
    },
    async toggleConvoPanelMeeting({ commit }, id) {
      commit('TOGGLE_PANEL_MEETING', id)
    },
    clearFields({ commit }) {
      commit('LOAD_FIELDS', [])
    },
    async fetchFields({ commit }, data) {
      commit('LOAD_FIELDS', [])
      const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/users/save-fields`, {
        table: data.table,
        fields: data.fields,
      })
      commit('LOAD_FIELDS', resp.data[data.table])
      commit('SAVE_TABLE', data.table)
    },
    saveCurrentRoutePermission({ commit }, permission) {
      commit('SAVE_CURRENT_ROUTE_PERMISSION', permission)
    },
    async fetchUserFields({ commit, state }, table) {
      if (state.currentQuery) {
        state.currentQuery.cancel('Request canceled by user')
      }

      commit('SAVE_CURRENT_QUERY', axios.CancelToken.source())

      const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users/get-fields/${table}?permission=${state.currentRoutePermission}`, { cancelToken: state.currentQuery.token })
      if (resp.status === 200) {
        commit('LOAD_FIELDS', resp.data[table])
        commit('SAVE_TABLE', table)
      }
    },
    async fetchAuxFields({ commit }, auxFields) {
      commit('LOAD_FIELDS', auxFields)
    },
    // async fetchHasReation({ commit }, data) {
    //   // data tendrá el modelo por ejempllo: App\Models\Institution y un id
    //   const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/users/get-fields/${data.id}`)
    //   if (resp.status === 200) {
    //     commit('SAVE_HASRELATION', resp.data)
    //   }
    // },
    async saveDeleteData({ commit }, deleteData) {
      commit('LOAD_DELETE_DATA', deleteData)
    },
    async saveOtpData({ commit }, otpData) {
      commit('LOAD_OTP_DATA', otpData)
    },
    async saveReportData({ commit }, reportData) {
      commit('LOAD_REPORT_DATA', reportData)
    },
    async saveReorganizeData({ commit }, reorganizeData) {
      commit('LOAD_REORGANIZE_DATA', reorganizeData)
    },
    async showAdminMenu({ commit }, show) {
      commit('SHOW_ADMIN_MENU', show)
    },
    async togglePromotionCall({ commit }, show) {
      commit('SHOW_PROMOTION_CALL', show)
    },
    async toggleProposeEvaluator({ commit }, show) {
      commit('SHOW_PROPOSE_EVALUATOR', show)
    },
    async toggleConvoViewPeers({ commit }, show) {
      commit('SHOW_CONVO_VIEW_PEERS', show)
    },
    async toggleShowEvaluation({ commit }, show) {
      commit('TOGGLE_SHOW_EVALUATION', show)
    },
  },
}
